import SearchIcon from '@mui/icons-material/Search'
import {
  Box, Card, Chip, Divider, Grid, InputAdornment, Stack, TextField, Typography
} from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TableFilterContainer from '../../../../components/EditableTable/TableFilterContainer'
import { maxWidth800px } from '../../../../constans/mediaQuery'
import { stringifyLocationSearch } from '../../../../utils/locationSearch'
import {
  boardsWrap,
  GridRoot,
  StackStyle,
  StatusHeader,
  StatusText
} from '../../../Logistics/OrdersDashboard/styles'
import {
  DASHBOARD_CONFIG, INVOICE_CATEGORY, INVOICE_STATUS_OPTIONS, INVOICE_TYPE_OPTIONS,
} from '../constants'
import { CardHeaderSx } from '../styles'
import DashboardInvoiceCard from './components/DashboardInvoiceCard'
import InvoiceForm from './components/InvoiceForm'

const Dashboard = ({ props }) => {
  const { filterState, setFilterState, setSearch } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [locationInvoiceId, setLocationInvoiceId] = useState(location?.search?.split('=')?.[1] || null);

  const handleFilterState = useCallback((value, type) => {
    setFilterState(prev => ({
      ...prev,
      [type]: value
    }));
  }, []);

  const handleCloseInvoice = useCallback(() => {
    navigate(location.pathname);
    setLocationInvoiceId(null);
  }, []);

  const handleOpenInvoice = useCallback((id) => {
    setLocationInvoiceId(id);
    navigate(location.pathname + stringifyLocationSearch({
      invoice: id
    }));
  }, []);

  const filterConfig = {
    afterDivider: false,
    items: [
      {
        value: filterState.payment_status,
        callback: (e) => handleFilterState(e.target.value, 'payment_status'),
        closeCallback: () => handleFilterState(null, 'payment_status'),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: INVOICE_STATUS_OPTIONS,
        placeholder: 'Select Status'
      },
      {
        value: filterState.payment_type,
        callback: (e) => handleFilterState(e.target.value, 'payment_type'),
        closeCallback: () => handleFilterState(null, 'payment_type'),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: INVOICE_TYPE_OPTIONS,
        placeholder: 'Select Type'
      },
      {
        value: filterState.invoice_type,
        callback: (e) => handleFilterState(e.target.value, 'invoice_type'),
        closeCallback: () => handleFilterState(null, 'invoice_type'),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: INVOICE_CATEGORY,
        placeholder: 'Select Category'
      }
    ]
  };

  const renderGridItems = () => DASHBOARD_CONFIG.map(({
    title, statuses, bgColor, permissions 
  }) => {
    const taskData = props.handleTasksByStatus(statuses);
    
    return (
      <Grid item xs={2.4} key={title}>
        <Card sx={{ backgroundColor: bgColor }}>
          <Box sx={StatusHeader}>
            <Box>
              <Typography noWrap variant="h6" sx={StatusText}>
                {title}
              </Typography>
            </Box>
            <Box>
              <Chip label={taskData.count} variant="outlined" />
            </Box>
          </Box>
          <Divider />
          <Stack spacing={2} sx={StackStyle}>
            {taskData.invoices?.map((invoice) => (
              <DashboardInvoiceCard
                key={invoice.id}
                props={props}
                invoice={invoice}
                onOpen={handleOpenInvoice}
                permissions={permissions}
              />
            ))}
          </Stack>
        </Card>
      </Grid>
    );
  });

  return (
    <>
      <Card sx={{ mt: 3, padding: '0 0.7rem 0.7rem 0.7rem' }}>
        <TableFilterContainer filterConfig={filterConfig} />
      </Card>
      <Card sx={CardHeaderSx}>
        <Box sx={{
          display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: '12px'
        }}
        >
          <TextField
            size="small"
            autoComplete="off"
            type="text"
            sx={{
              minWidth: '300px', maxWidth: '300px'
            }}
            placeholder="Search this board"
            onChange={debounce((e) => setSearch(e.target.value), 400)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Grid container sx={GridRoot}>
          <Grid
            container
            sx={{
              ...boardsWrap,
              ...(maxWidth800px() && { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' })
            }}
            spacing={6}
          >
            {renderGridItems()}
          </Grid>
        </Grid>
        <InvoiceForm onClose={handleCloseInvoice} locationInvoiceId={locationInvoiceId} props={props} />
      </Card>
    </>
  );
};

export default Dashboard;
