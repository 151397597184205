import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card, CardContent, Divider,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../../../components/Button';
import Upload from '../../../../../../components/Upload';
import Autocomplete from '../../../../../../form/components/Autocomplete/Autocomplete';
import DatePicker from '../../../../../../form/components/DatePicker';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import { useCreateInvoiceDocMutation, useGetCurrencyQuery } from '../../../../../../store/session';
import { getOptions } from '../../../../../../utils/getOptions';
import { numberUnFormat } from '../../../../../../utils/number';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import {
  block, button, buttonBlock, file, fileName, label
} from '../../../../../DocumentsAndInvoices/Invoices/InvoicesForm/style';
import { defaultValues, schema } from './payedInvoiceFormSchema';

const PayedInvoiceForm = (props) => {
  const {
    parentId, refetchInvoice, refetchBoard = () => {}, onClose = () => {}
  } = props;

  const [nameFile, setNameFile] = useState();

  const [sendInvoice] = useCreateInvoiceDocMutation();
  const { data: currency } = useGetCurrencyQuery({
    refetchOnMountOrArgChange: true
  });

  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const currencyOptions = getOptions(currency);

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('description', data.description);
    formData.append('invoice_number', data.invoice_number);
    formData.append('invoice_name', data.invoice_name);
    formData.append('file', data.file);
    formData.append('invoice_sum', data.invoice_sum);
    formData.append('currency_id', data.currency_id);
    formData.append('due_payment_date', data.due_payment_date);
    formData.append('payment_type', 'flight');
    formData.append('predicted_invoice_sum', data.predicted_invoice_sum);
    formData.append('parent_id', data.parent_id);
    formData.append('is_proforma', 0);

    const res = await sendInvoice(formData);

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success('The invoice has been sent!', {
      id: toastId,
    });

    reset();
    refetchBoard();
    onClose();

    setValue('currency', {});
    setValue('currency_id', {});
    document.getElementById('file-upload').value = '';
    setNameFile(null);
  };

  useEffect(() => {
    if (parentId) {
      setValue('parent_id', parentId);
    }
  }, [parentId]);

  return (
    <Card sx={{ mt: 5, border: '1px solid whitesmoke', width: 'auto' }}>
      <CardContent sx={{ mt: 5 }}>
        <form>
          <Box sx={file}>
            <FormGroup sx={label} required label="File" hasError={!!errors.file}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <Upload
                    {...field}
                    accept=".doc,.docx,image/*,.pdf"
                    handleUpload={(e) => {
                      field.onChange(e);
                      uploadFile(e);
                    }}
                    title="Upload file"
                  />
                )}
              />
              <FieldError error={errors.file} />
            </FormGroup>
            <Typography sx={fileName}>{nameFile}</Typography>
          </Box>

          <Box sx={block}>
            <FormGroup sx={label} label="Description" required hasError={!!errors.description}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={4}
                    multiline
                    variant="filled"
                    sx={{ width: '100%' }}
                  />
                )}
              />
              <FieldError error={errors.description} />
            </FormGroup>
          </Box>

          <Box sx={{ ...block, position: 'relative' }}>
            <FormGroup sx={label} label="Invoice Number" required hasError={!!errors.invoice_number}>
              <Controller
                name="invoice_number"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    sx={{ width: '100%' }}
                  />
                )}
              />
              <FieldError error={errors.invoice_number} />
            </FormGroup>
          </Box>

          <Box sx={{ ...block, position: 'relative' }}>
            <FormGroup sx={label} label="Supplier" required hasError={!!errors.invoice_name}>
              <Controller
                name="invoice_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    sx={{ width: '100%' }}
                  />
                )}
              />
              <FieldError error={errors.invoice_name} />
            </FormGroup>
          </Box>

          <Box sx={block}>
            <FormGroup sx={label} label="Deadline Payment" hasError={!!errors.due_payment_date}>
              <Controller
                name="due_payment_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'due_payment_date', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
              <FieldError error={errors.due_payment_date} />
            </FormGroup>
          </Box>

          <Box sx={block}>
            <FormGroup sx={label} label={watch('is_proforma') ? 'Proforma SUM' : 'Invoice SUM'} required hasError={!!errors.invoice_sum}>
              <Controller
                name="invoice_sum"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      setValue('invoice_sum', numberUnFormat(e.target.value, true));
                    }}
                  />
                )}
              />
              <FieldError error={errors.invoice_sum} />
            </FormGroup>
          </Box>

          {!!watch('is_proforma') && (
          <Box sx={block}>
            <FormGroup sx={label} label="Invoice SUM" required hasError={!!errors.predicted_invoice_sum}>
              <Controller
                name="predicted_invoice_sum"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      setValue('predicted_invoice_sum', numberUnFormat(e.target.value, true));
                    }}
                  />
                )}
              />
              <FieldError error={errors.predicted_invoice_sum} />
            </FormGroup>
          </Box>
          )}

          <Box sx={block}>
            <FormGroup sx={label} required label="Currency" hasError={!!errors.currency}>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={field.value ? currencyOptions?.filter((item) => item.value === field?.value?.value)[0] : null}
                    options={currencyOptions}
                    size="small"
                    placeholder="Please select a currency"
                    onChange={(e, value ) => {
                      field.onChange(value);
                      setValue('currency', value || null);
                      setValue('currency_id', value?.value || null);
                      trigger('currency', value);
                    }}
                  />
                )}
              />
              <FieldError error={errors.currency} />
            </FormGroup>
          </Box>

          <Box sx={buttonBlock}>
            <Button
              sx={button}
              title="Send"
              onClick={handleSubmit(onSubmit)}
            />
          </Box>

          <Divider orientation="vertical" variant="middle" flexItem />
        </form>
      </CardContent>
    </Card>
  );
};

export default PayedInvoiceForm;
