import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { ListStatus } from 'mdi-material-ui';
import React from 'react';
import Button from '../../../components/Button';
import { HeaderName } from '../../../components/EditableTable/styles';
import { PRIORITY, handleStatusChip } from '../../../constans/logistics';
import { fetchOrder } from '../../../store/slices/logisticsSlice';
import { formatDate } from '../../../utils/getTime';
import { GreenButton, TextWrap } from '../style';
import { getOrderType } from '../utils';

export const columns = ({
  handleOpenOrder,
  dispatch,
  dashboardType,
}) => ([
  {
    flex: 0.1,
    field: 'actions',
    minWidth: 120,
    maxWidth: 180,
    headerName: 'Actions',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}></Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button
          sx={{ ...GreenButton, fontSize: '0.75rem', padding: '0.4rem' }}
          onClick={() => {
            handleOpenOrder(row?.id);
            dispatch(fetchOrder({ orderId: row?.id, refetch: 0 }));
          }}
        >
          Open Order
        </Button>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'id',
    minWidth: 150,
    headerName: '#',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>{(dashboardType === 'orders') ? 'Order ID' : 'Delivery ID'}</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>
          #
          {row.id}
          {(dashboardType === 'delivery') && 'D'}
        </Typography>
      </Box>
    ),
  }, // id
  {
    flex: 0.1,
    field: 'priority',
    minWidth: 50,
    headerName: 'Priority',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <PriorityHighIcon />
      </Box>
    ),
    renderCell: ({ row }) => PRIORITY.find(i => i.value === row?.priority)?.icon
  }, // Priority
  {
    flex: 0.15,
    field: 'status',
    minWidth: 120,
    headerName: 'Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px'
        }}
      >
        <ListStatus />
        Status
      </Box>
    ),
    renderCell: ({ row }) => handleStatusChip(row.status, row?.decline_reason, row?.ttm_code)
  }, // Status
  {
    flex: 0.2,
    field: 'aircraft',
    minWidth: 140,
    headerName: 'Aircraft Reservation',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Aircraft Reservation</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={(row?.aircraft || row?.aircraft_reservation) ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    )
  }, // Aircraft
  ((dashboardType === 'orders') && {
    flex: 0.15,
    field: 'storage',
    minWidth: 140,
    headerName: 'Storage',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Storage</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.storage ?? 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    )
  }), // Aircraft
  ((dashboardType === 'orders') && {
    flex: 0.2,
    field: 'work_order_task',
    minWidth: 140,
    headerName: 'Work Order Task',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Work Order Task</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      if (row.work_order_task) {
        return (
          <Typography>
            <Link style={{ 'textDecoration': 'none' }} to={`/maintenance/work-order-task/update/${row.work_order_task}`} target="_blank">{row.name}</Link>
          </Typography>
        );
      }

      return (
        <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
          <Chip label="N/A" color="secondary" variant="outlined" />
        </Typography>
      );
    },
  }), // Work Order Task
  ((dashboardType === 'orders') && {
    flex: 0.15,
    field: 'part_number',
    minWidth: 140,
    headerName: 'Part Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Part Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.part_number}</Typography>
      </Box>
    )
  }), // Part Number
  ((dashboardType === 'orders') && {
    flex: 0.15,
    field: 'part_name',
    minWidth: 140,
    headerName: 'Part Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Part Name</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.part_name ?? 'N/A'}</Typography>
      </Box>
    )
  }), // Part Name
  {
    flex: 0.15,
    field: 'part_type',
    minWidth: 120,
    headerName: 'Part Type',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Part Type</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{getOrderType(row?.part_type) ?? 'N/A'}</Typography>
      </Box>
    )
  }, // Part Type
  ((dashboardType === 'orders') && {
    // flex: 0.2,
    field: 'transaction_type',
    minWidth: 160,
    headerName: 'Transaction Type',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Transaction Type</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
        <Chip label={row?.prices?.[0]?.transaction_type || 'N/A'} color="secondary" variant="outlined" />
      </Typography>
    )
  }),
  {
    // flex: 0.2,
    field: (dashboardType === 'orders') ? 'part_count' : 'quantity',
    minWidth: 80,
    headerName: 'Quantity',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Quantity</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      const isArr = Array.isArray(row?.quantity);
      let currQuantity = 0;

      if (isArr) {
        row?.quantity.forEach((item) => {
          if (typeof item === 'object') {
            currQuantity += +item?.count;
    
            return;
          } 
          if ((typeof item === 'number') || (typeof item === 'string')) {
            currQuantity += +item;
          }
        }); 
      }

      return (
        <Box>
          <Typography sx={TextWrap}>{isArr ? currQuantity : row?.quantity ? row?.quantity : row?.part_count ? `${row.part_count} ${row.unit ?? ''}` : ''}</Typography>
        </Box>
      );
    }
  }, // Quantity
  {
    flex: 0.2,
    field: 'created_by',
    minWidth: 120,
    headerName: 'Created by',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Created by</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>
          {row?.creator?.name || row?.created_by}
        </Typography>
      </Box>
    )
  }, // Created by
  {
    flex: 0.2,
    field: (dashboardType === 'orders') ? 'created' : 'created_at',
    minWidth: 120,
    headerName: 'Created at',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Created at</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>
          {row?.created || formatDate(row?.created_at)}
        </Typography>
      </Box>
    )
  }, // Created at / Update at
  {
    flex: 0.2,
    field: 'approved_by',
    minWidth: 120,
    headerName: 'Approved by',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Approved by</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.approver?.name}</Typography>
      </Box>
    )
  }, // Approved by
  {
    flex: 0.2,
    field: (dashboardType === 'orders') ? 'approved' : 'approved_at',
    minWidth: 120,
    headerName: 'Approved at',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Approved at</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.approved || (row?.approved_at ? formatDate(row?.approved_at) : '')}</Typography>
      </Box>
    )
  }, // Approved at
  ((dashboardType === 'delivery') && {
    flex: 0.2,
    field: 'address',
    minWidth: 120,
    headerName: 'Address',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Address</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.address}</Typography>
      </Box>
    )
  }), // Address
  ((dashboardType === 'delivery') && {
    flex: 0.2,
    field: 'description',
    minWidth: 120,
    headerName: 'Description',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Description</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.description}</Typography>
      </Box>
    )
  }), // Description
].filter(item => !!item));
