import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  CardContent, Divider, Typography, useMediaQuery
} from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PRIORITY } from '../../../../constans/logistics';
import { useAppDispatch } from '../../../../hooks/toolkitHooks';
import { fetchInternalOrder } from '../../../../store/slices/internalLogisticsSlice';
import { fetchCurrencies, fetchOrder } from '../../../../store/slices/logisticsSlice';
import { formatDate } from '../../../../utils/getTime';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import { headerBtn, headerBtnS } from '../../../Invoices/style';
import { TextWithIcon } from '../../style';
import { getOrderType } from '../../utils';
import { Item, JobChipLink, StatusText } from '../styles';

const TaskCard = ({ task, openOrder }) => {
  const location = useLocation();
  const dashboardType = location.pathname.split('/')[3];
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const dispatch = useAppDispatch();
  const baseElevation = 4;
  const activeElevation = 10;
  const [shadow, setShadow] = useState(baseElevation);
  const hasPermissionsWorkOrder = usePermissions('wo_access');
  const maxWidth1500px = useMediaQuery('(max-width:1500px)');

  const handleOpenOrder = (id) => {
    setFetchTrigger((prev) => prev + 1);
    openOrder(id);
    dispatch(fetchCurrencies());
    
    if (dashboardType === 'orders') {
      dispatch(fetchOrder({ orderId: id, refetch: fetchTrigger }));
    } else {
      dispatch(fetchInternalOrder({ orderId: id, refetch: fetchTrigger }));
    }
  };
  
  const handleOpenJob = (e) => {
    e.stopPropagation();
    window.open(`/maintenance/work-order-task/update/${task.job_id}`, '_blank', 'noreferrer');
  };

  const quantitySum = (quantityArr = []) => {
    let currQuantity = 0;

    quantityArr.forEach((item) => {
      if (typeof item === 'object') {
        currQuantity += +item?.count;

        return;
      } 
      if ((typeof item === 'number') || (typeof item === 'string')) {
        currQuantity += +item;
      }
    }); 

    return currQuantity;
  };

  return (
    <Item
      elevation={shadow}
      onMouseOver={() => setShadow(activeElevation)}
      onMouseLeave={() => setShadow(baseElevation)}
      onClick={() => handleOpenOrder(task.id)}
    >
      <CardContent sx={{
        position: 'relative'
      }}
      >
        <Box sx={{
          ...headerBtn, ...(maxWidth1500px && headerBtnS), display: 'flex', justifyContent: 'space-between' 
        }}
        >
          <Box>
            <Typography noWrap variant="subtitle1" sx={StatusText}>
              Order #
              {task.id}
              {(dashboardType === 'delivery') && 'D'}
            </Typography>
          </Box>

          <Box sx={TextWithIcon}>
            {(task.job_id && hasPermissionsWorkOrder) && (
              <Chip label="WO-JOB" variant="outlined" size="small" sx={JobChipLink} onClick={(e) => handleOpenJob(e)} />
            )}
            {task.aircraft && (<Chip label={task.aircraft} variant="outlined" size="small" />)}
            {PRIORITY.find(i => i.value === task.priority).icon}
          </Box>
        </Box>

        <Divider />

        <Box>
          {(task?.part_number && !['delivery', 'Overhaul_out', 'Repair_out'].includes(task?.part_type)) && (
          <Typography noWrap variant="body1">
            <b>Pt. Number:</b>
            {' '}
            {task.part_number}
          </Typography>
          )}
          {(task?.part_name && !['delivery', 'Overhaul_out', 'Repair_out'].includes(task?.part_type)) && (
          <Typography noWrap variant="body1">
            <b>Pt. Name:</b>
            {' '}
            {task?.part_name ?? 'N/A'}
          </Typography>
          )}
          {task?.part_type && (
          <Typography noWrap variant="body1" sx={{ textTransform: 'capitalize' }}>
            <b>Pt. Type:</b>
            {' '}
            {getOrderType(task.part_type)}
          </Typography>
          )}
          {(task?.quantity || task?.part_count) && (
          <Typography noWrap variant="body1">
            <b>Quantity:</b>
            {' '}
            {Array.isArray(task?.quantity) ? quantitySum(task?.quantity)
              : (task?.quantity || `${task?.part_count} ${task?.unit ?? ''}`)}
          </Typography>
          )}
          {((dashboardType === 'delivery') && (Array.isArray(task?.parts))) && task?.parts.map((item, index) => (
            <Typography noWrap variant="body1">
              <b>
                Part Name
                {' '}
                {index + 1}
                :
              </b>
              {' '}
              {item?.part_name}
            </Typography>
          ))}
          {(task?.address_from) && (
          <Typography noWrap variant="body1">
            <b>Address From:</b>
            {' '}
            {task?.address_from}
          </Typography>
          )}
          {(task?.address_to) && (
          <Typography noWrap variant="body1">
            <b>Address To:</b>
            {' '}
            {task?.address_to}
          </Typography>
          )}
        </Box>

        <Divider />

        <Box>
          {task.status === 0 && (
            <>
              <Typography noWrap variant="subtitle2">
                Sent at:
                {' '}
                {task?.created || formatDate(task?.created_at)}
              </Typography>
              <Typography noWrap variant="subtitle2">
                Sent by:
                {' '}
                {task?.creator?.name || task.created_by}
              </Typography>
            </>
          )}

          {task.status === 1 && (
            <>
              <Typography noWrap variant="subtitle2">
                Requested at:
                {' '}
                {task?.requested || task?.requested_at || formatDate(task?.created_at)}
              </Typography>
              <Typography noWrap variant="subtitle2">
                Requested by:
                {' '}
                {task?.requester?.name || task?.creator?.name}
              </Typography>
            </>
          )}

          {task.status === 2 && (
            <>
              <Typography noWrap variant="subtitle2">
                Approved at:
                {' '}
                {task?.approved || task?.approved_at}
              </Typography>
              <Typography noWrap variant="subtitle2">
                Approved by:
                {' '}
                {task?.approver?.name}
              </Typography>
            </>
          )}

          {task.status === 3 && (
            <>
              <Typography noWrap variant="subtitle2">
                Ordered at:
                {' '}
                {task?.ordered_at}
              </Typography>
              <Typography noWrap variant="subtitle2">
                Ordered by:
                {' '}
                {task?.ordered?.name}
              </Typography>
            </>
          )}
        </Box>
        {(((task.status === 2) || (task.status === 3)) && (task?.prices?.[0]?.transaction_type === 'exchange')) && (
        <Box sx={{
          width: '2.5rem',
          height: '2.5rem',
          borderRadius: '50%',
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          >
            <SyncAltIcon sx={{
              fontSize: '1.5rem'
            }}
            />
          </Box>
        </Box>
        )}
      </CardContent>
    </Item>
  );
};

export default TaskCard;
