import {
  Box,
  Card,
  Divider,
  Grid,
  Tab, Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../../../../../components/Button';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import ServerAutocomplete from '../../../../../../form/components/ServerAutocomplete';
import { useGetInternalPaymentRequestsQuery } from '../../../../../../store/slices/internalLogisticsSlice';
import { useGetPaymentRequestsQuery, useSearchSupplierQuery } from '../../../../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../../../../utils/hooks/usePermissions';
import {
  Filters, GreenButton, RedButton,
} from '../../../../style';
import { noticeStyle } from '../../../styles';
import ExchangeDueDate from '../exchangeDueDate/ExchangeDueDate';
import InvoiceCard from './invoices/InvoiceCard';
import InvoiceTableHeader from './invoices/InvoiceTableHeader';
import InvoicesForm from './invoices/InvoicesForm';
import SupplierInvoice from './invoices/Suppliers/SupplierInvoice';
import { useApprovedForm } from './useApprovedForm';

const ApprovedForm = ({ onClose, refetch }) => {
  const { order: currentOrder } = useSelector((state) => state.logistics);
  const { order: internalOrder } = useSelector((state) => state.internalLogistics);
  const changeTypes = ['Overhaul_out', 'Repair_out', 'Exchange'];
  const location = useLocation();
  const dashboardType = location.pathname.split('/')[3];
  const [activeTab, setActiveTab] = useState('ttn');
  const order = dashboardType === 'orders' ? currentOrder : internalOrder;
  const { data: currentPartData } = useGetPaymentRequestsQuery({ orderId: order?.id, payment_type: (activeTab === 'ttn') ? 'delivery' : activeTab }, {
    skip: (dashboardType !== 'orders')
  });
  const { data: internalData } = useGetInternalPaymentRequestsQuery({ orderId: order?.id, payment_type: (activeTab === 'part') ? order?.part_type === 'Repair_out' ? 'overhaul' : order?.part_type?.toLowerCase() : 'delivery' }, {
    refetchOnMountOrArgChange: true,
    skip: dashboardType === 'orders'
  });

  const deliverData = dashboardType === 'orders' ? currentPartData : internalData;
  const partData = dashboardType === 'orders' ? currentPartData : internalData;
  const disabledDeliveryOperator = Object.values(deliverData || {})?.some(item => ((item?.payment_status === 6) || (item?.payment_status === 2) || (item?.payment_status === 3)));

  let existInvoice = false;
  if (activeTab === 'part' && partData) {
    existInvoice = Object.values(partData)?.find(item => item.is_proforma === 0 && item.payment_status !== 5); // Not decline
  }
  if (activeTab === 'delivery' && deliverData) {
    existInvoice = Object.values(deliverData)?.find(item => item.is_proforma === 0 && item.payment_status !== 5); // Not decline
  }
 
  const userAccess = usePermissions('logist');
  const internalLogist = usePermissions('internal_logist');
  const userTabAccess = usePermissions('invoice_request_logist');
  const internalUserTabAccess = usePermissions('internal_invoice_request_logist');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const {
    formMethods,
    handleSubmit,
    onSubmit,
    isLoading
  } = useApprovedForm({ order, onClose, dashboardType });

  const { errors } = formMethods.formState;

  const isRemovePartsInvoicesRequests = (order?.part_type === 'delivery') || (order?.part_type === 'Other') || (order?.part_type === 'Return');
  const isChangeNamePartsInvoicesRequests = changeTypes.includes(order?.part_type) ? `${changeTypes.find(item => item === order?.part_type)} Requests` : 'Parts Invoices Requests';

  useEffect(() => {
    if (order?.delivery_operator) {
      formMethods.setValue('delivery_operator_id', order?.delivery_operator?.id);
    }
  }, [order]);

  if (dashboardType === 'orders' ? !userAccess : !internalLogist) return <Typography sx={{ margin: '20px 0', textAlign: 'center' }}>You don&apos;t have access.</Typography>;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          sx={{ mt: 0, pt: 0 }}
          value={activeTab}
          onChange={handleTabChange}
          variant="fullWidth"
          centered
          aria-label="basic tabs example"
        >
          <Tab label="Tracking Number" value="ttn" />
          {((order?.prices?.[0]?.transaction_type === 'exchange') && (dashboardType === 'orders')) && <Tab label="Exchange Due Date" value="exchange" />}
          {(dashboardType === 'orders' && userTabAccess) && <Tab label="Purchase Order" value="supplier" />}
          {(!isRemovePartsInvoicesRequests && ((dashboardType === 'orders') ? userTabAccess : internalUserTabAccess)) && <Tab label={isChangeNamePartsInvoicesRequests} value="part" />}
          {((dashboardType === 'orders') ? userTabAccess : internalUserTabAccess) && <Tab label="Delivery Invoices Requests" value="delivery" />}
        </Tabs>
      </Box>

      {order?.approver_notice && (
      <Grid item xs={12} sx={{ padding: '1rem 1rem 1rem 1rem' }}>
        <FormGroup label="Current Notice" hasError={!!errors.storage}>
          <TextField
            id="standard-basic"
            placeholder="You can indicate some important notes"
            row={6}
            multiline
            variant="standard"
            value={order?.approver_notice}
            disabled
            sx={noticeStyle}
          />
        </FormGroup>
      </Grid>
      )}
      {activeTab === 'ttn' && (
        <FormProvider {...formMethods}>
          <form>
            <Card
              sx={{ padding: '1rem 1rem 1rem 1rem' }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <FormGroup label="TTM Code" required hasError={!!errors.ttm_code}>
                    <Controller
                      name="ttm_code"
                      control={formMethods.control}
                      defaultValue=""
                      render={({ field }) => (
                        <Input placeholder="Enter Tracking Number" {...field} />
                      )}
                    />
                    <FieldError error={errors.ttm_code} />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup label="Delivery operator" required hasError={!!errors.delivery_operator_id}>
                    <Controller
                      control={formMethods.control}
                      name="delivery_operator_id"
                      render={({ field }) => (
                        <ServerAutocomplete
                          withData
                          placeholder={order?.delivery_operator?.id ? order?.delivery_operator?.name : 'Search operators by name or email'}
                          disabled={disabledDeliveryOperator}
                          searchQueryFn={useSearchSupplierQuery}
                          handleSelect={(value) => {
                            field.onChange(value?.value);
                          }}
                        />
                      )}
                    />
                    <FieldError error={errors.delivery_operator_id} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                  <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                    <Box>
                      <Button
                        sx={{ mt: 2, ...(RedButton) }}
                        variant="contained"
                        size="large"
                        title="Cancel"
                        onClick={onClose}
                      />
                    </Box>
                    <Box>
                      <Button
                        sx={{ mt: 2, ...(GreenButton) }}
                        variant="contained"
                        size="large"
                        disabled={!isLoading}
                        onClick={handleSubmit(onSubmit)}
                        title="Submit"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </form>
        </FormProvider>
      )}
      {((order?.prices?.[0]?.transaction_type === 'exchange') && (activeTab === 'exchange')) && (
        <ExchangeDueDate orderId={order?.id} refetchOrder={refetch} exchangeDueDate={order?.exchange_due_date} />
      )}
      {(activeTab === 'supplier') && (
        <SupplierInvoice refetch={refetch} />
      )}

      {(activeTab === 'part') && (
        <Box p={3}>
          {partData && Object.values(partData)?.length ? (
            <>
              <InvoiceTableHeader />
              {Object.values(partData)?.map((element) => (
                <InvoiceCard key={element.id} invoice={element} />
              ))}
              <Divider sx={{ pt: '10px' }} />
            </>
          ) : (
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center'
            }}
            >
              <Typography variant="h5" noWrap>
                No related invoices
              </Typography>
              <Typography variant="subtitle2" noWrap>
                Please add new invoice.
              </Typography>
            </Box>
          )}
          {!existInvoice && (
            <InvoicesForm
              order={order}
              variant={
                ['Overhaul_out', 'Repair_out'].includes(order?.part_type) ? 'overhaul'
                  : (order?.part_type === 'Exchange') ? 'exchange' : 'part'
              }
              dashboardType={dashboardType}
            />
          )}
        </Box>
      )}
      {(activeTab === 'delivery') && (
        <Box p={3}>
          {deliverData && Object.values(deliverData)?.length ? (
            <>
              <InvoiceTableHeader />
              {Object.values(deliverData)?.map((element) => (
                <InvoiceCard key={element.id} invoice={element} />
              ))}
              <Divider sx={{ pt: '10px' }} />
            </>
          ) : (
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center'
            }}
            >
              <Typography variant="h5" noWrap>
                No related invoices
              </Typography>
              <Typography variant="subtitle2" noWrap>
                Please add new invoice.
              </Typography>
            </Box>
          )}
          {!existInvoice && <InvoicesForm order={order} variant="delivery" dashboardType={dashboardType} />}
        </Box>
      )}
    </>
  );
};

export default ApprovedForm;
