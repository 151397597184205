import {
  Box,
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow
} from '@mui/material';
import { ArrowRight, Plus } from 'mdi-material-ui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import Checkbox from '../../../../form/components/Checkbox/Checkbox';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import { getLegValues } from '../../../../utils/fdp';
import { StyledDelete } from '../../../Flights/list/styles';
import { FeesModal } from './FeesModal';

const HopsForm = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changedAirport, setChangedAirport] = useState(null);

  const {
    onGetPrice,
    hopFields,
    Controller,
    control,
    watch,
    setValue,
    errors,
    trigger,
    getValues,
    hopRemove,
    airportListLoad,
    AircraftList,
    AirportList,
    setQuotation,
    refetchAirportList,
    AircraftTypesList,
    resetAirport,
    handleSetNewestHops
  } = props;

  const [formattedAirportList, setFormattedAirportList] = useState([]);
  const fieldForReqValues = ['hop_empty', 'hop_end ', 'hop_start', 'hop_start_id', 'hop_end_id', 'fleet_regofac', 'fleet_crew_ready', 'fleet_payload'];
  const fieldFdpTriggerValues = ['hop_end', 'hop_start', 'hop_start_id', 'hop_end_id', 'hop_empty', 'fleet_acmi', 'total_fuel_price', 'fleet_con_additional', 'fleet_cond_nav', 'fleet_cond_other', 'fleet_regofac'];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChangedAirport = (airport) => {
    setChangedAirport(airport);
  };

  const addNewHop = async () => {
    const hops = getValues('hops');
    const newHopIndex = hops.length;
    const isValid = await trigger();

    if (isValid) {
      const newHop = {
        hop_empty: true,
        hop_trip: '',
        hop_departure: hops?.[newHopIndex - 1]?.arrivingTime,
        hop_start_id: hops?.[newHopIndex - 1]?.hop_end_id,
        hop_start: hops?.[newHopIndex - 1]?.hop_end,
        hop_end: null,
        hop_end_id: null,
        hop_q_kg: '',
        hop_ap_fees_f: hops?.[newHopIndex - 1]?.hop_ap_fees_t,
        hop_ap_fees_t: '',
        hop_maxpl_kg: '',
        hop_distance: '',
        hop_flttime: '',
        fuel_price: '',
        leg_fuel_price: '',
      };

      const hopsItems = [...hops, newHop];

      setValue('hops', hopsItems);
    }
  };

  const getFeesKey = (list = null) => {
    return 'atr42';
    const aircraftTypeId = watch('fleet_typeofac');
    const aircraftType = (list || AircraftTypesList).find(item => item.id === aircraftTypeId);
    if (aircraftType?.aircraft_type_name) {
      const typeName = aircraftType.aircraft_type_name.toLowerCase();
      if (typeName.includes('atr42')) {
        return 'atr42';
      }
      if (typeName.includes('atr72')) {
        return 'atr72';
      }
    }

    return null;
  };

  const handleChangeStartAirport = (e, airport, index, start) => {
    const nextAirport = watch(`hops.${index + 1}`);

    if (nextAirport && !start) {
      setValue(`hops.${index + 1}.hop_start`, airport?.label || '');
      setValue(`hops.${index + 1}.hop_start_id`, airport?.id || null);
    }

    if (!airport) {
      setValue(`hops.${index}.hop_departure`, '');
      setValue(`hops.${index}.hop_distance`, '');
      setValue(`hops.${index}.hop_flttime`, '');
      setValue(`hops.${index}.fuel_price`, '');
      setValue(`hops.${index}.hop_q_kg`, '');
      setValue(`hops.${index}.hop_maxpl_kg`, '');
      setValue(`hops.${index}.leg_fuel_price`, '');

      if (start) {
        setValue(`hops.${index}.hop_ap_fees_f`, '');

        return;
      }

      setValue(`hops.${index}.hop_ap_fees_t`, '');

      return;
    }

    const currentHop = getValues(`hops.${index}`);

    const fees = !currentHop.hop_empty ? 'airport_fees_f_data' : 'airport_fees_e_data';
    const feesKey = getFeesKey();
    if (start) {
      if (feesKey) {
        setValue(`hops.${index}.hop_ap_fees_f`, airport[fees][feesKey]);
      }
      setValue(`hops.${index}.hop_start`, airport?.label || '');

      return;
    }
    if (feesKey) {
      setValue(`hops.${index}.hop_ap_fees_t`, airport[fees][feesKey]);
    }
    setValue(`hops.${index}.hop_end`, airport?.label || '');
  };

  const handleChangeHops = (airport, airportList, hops = []) => {
    const hopsIndex = [];

    if (hops?.length) {
      hops.forEach((el, index) => {
        if ((+el?.hop_end_id === +airport) || (+el?.hop_start_id === +airport) || !airport) {
          hopsIndex.push(index);
        }
      });
    }

    if (airport) {
      const currentAirport = (airportList || []).find(el => el?.id === +airport);

      if (hopsIndex.length) {
        hopsIndex.forEach(el => {
          const isStart = (hops?.[el]?.hop_start_id === +airport);

          handleChangeStartAirport(null, currentAirport, el, isStart);
        });
      }
    } else if (hopsIndex.length) {
      hopsIndex.forEach(el => {
        const { hop_start_id = 0, hop_end_id = 0 } = hops?.[el];
        const startAirport = (airportList || []).find(el => el?.id === +hop_start_id);
        const endAirport = (airportList || []).find(el => el?.id === +hop_end_id);
        handleChangeStartAirport(null, startAirport, el, true);
        handleChangeStartAirport(null, endAirport, el);
      });
    }

    setChangedAirport(null);
    handleSetNewestHops(false);
    onGetPrice();
  };

  const handleRemoveHop = (index) => {
    hopRemove(index);

    const hops = getValues('hops');
    const newHops = hops.map((hp, i) => {
      if (i === 0) return hp;

      const startHop = hops?.[i - 1];

      return {
        ...hp,
        hop_start: startHop?.hop_end,
        hop_start_id: startHop?.hop_end_id,
        hop_ap_fees_f: startHop?.hop_ap_fees_t
      };
    });

    setValue('hops', newHops);
    onGetPrice();
  };

  useEffect(() => {
    if (!airportListLoad) {
      setFormattedAirportList(AirportList?.map(i => ({ ...i, value: i.id, label: `${i.airport_iata}/${i.airport_icao} (${i.airport_name})` })));
    }
  }, [airportListLoad, AirportList]);

  useEffect(() => {
    const subscription = watch(async(value, { name, type }) => {
      const formValues = getValues();
      const indexHop = name.split('.')[1];

      if (name === 'hops.0.hop_start_id') {
        handleChangeStartAirport(null, AirportList?.filter(i => i.id === value.hops[0].hop_start_id).map(i => ({ ...i, value: i.id, label: `${i.airport_iata}/${i.airport_icao} (${i.airport_name})` }))[0], 0, true);
      }

      if (name.split('.')[2] === 'hop_empty' && type === 'change') {
        const airportStart = AirportList.find(i => i.id === value.hops[indexHop].hop_start_id);
        const airportEnd = AirportList.find(i => i.id === value.hops[indexHop].hop_end_id);

        const feesKey = getFeesKey();
        if (feesKey) {
          if (!value.hops[indexHop].hop_empty) {
            setValue(`hops.${indexHop}.hop_ap_fees_f`, airportStart.airport_fees_f_data[feesKey]);
            setValue(`hops.${indexHop}.hop_ap_fees_t`, airportEnd.airport_fees_f_data[feesKey]);
          } else {
            setValue(`hops.${indexHop}.hop_ap_fees_f`, airportStart.airport_fees_e_data[feesKey]);
            setValue(`hops.${indexHop}.hop_ap_fees_t`, airportEnd.airport_fees_e_data[feesKey]);
          }
        }
      }

      if (fieldForReqValues.includes(name.split('.')[2] || name) && type === 'change') {
        formValues.hops.forEach(({ hop_start_id, hop_end_id, hop_empty }, index) => {
          const airportFrom = AirportList.find(i => i.id === hop_start_id);
          const airportTo = AirportList.find(i => i.id === hop_end_id);
          const plane = AircraftList.find((i => i.id === formValues.fleet_regofac));

          let departureTime;

          if (index === 0) {
            departureTime = formValues.fleet_crew_ready;
          } else {
            departureTime = moment(formValues.hops[index - 1].hop_arriving).add(1, 'hours');
          }

          if (airportFrom && airportTo && plane && departureTime) {
            const payload = !value.hops[index].hop_empty ? formValues.fleet_payload : 0;
            const aircraftId = watch('fleet_regofac');
            const aircraft = AircraftList.find(item => item.id === aircraftId);
            const consumption = aircraft.consumption ?? 0;
            const legValues = getLegValues(airportFrom, airportTo, plane, departureTime, !hop_empty, consumption, payload);
            setValue(`hops.${index}.hop_departure`, moment(departureTime).format('YYYY-MM-DD HH:mm'));
            setValue(`hops.${index}.hop_distance`, Math.round(legValues.distance));
            setValue(`hops.${index}.hop_flttime`, legValues.flight_time);
            setValue(`hops.${index}.hop_arriving`, moment(legValues.arrivingTime).format('YYYY-MM-DD HH:mm'));
            setValue(`hops.${index}.fuel_price`, legValues.fuelPrice);
            setValue(`hops.${index}.hop_q_kg`, legValues.qkg);
            setValue(`hops.${index}.hop_maxpl_kg`, legValues.maxPl);
            setValue(`hops.${index}.leg_fuel_price`, legValues.legFuelPrice);
          }

          onGetPrice();
        });
      }

      if (fieldFdpTriggerValues.includes(name.split('.')[2] || name) && type === 'change') {
        let isAirportSelected = true;

        formValues.hops.forEach(i => {
          if (!(i.hop_start_id && i.hop_end_id)) {
            isAirportSelected = false;
          }
        });

        if (!isAirportSelected) return;

        onGetPrice();
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, AirportList, AircraftList]);

  useEffect(() => {
    const hops = getValues('hops');

    if ((formattedAirportList && changedAirport) || (formattedAirportList && resetAirport)) {
      handleChangeHops(changedAirport, formattedAirportList, hops);
    }
  }, [formattedAirportList, changedAirport, resetAirport]);

  useEffect(() => {
    const hops = getValues('hops');
    const regofac = getValues('fleet_regofac');

    if (formattedAirportList.length && regofac) {
      handleChangeHops(null, formattedAirportList, hops);
    }
  }, [watch('fleet_regofac'), formattedAirportList]);

  const getHopsAirportList = () => {
    const formValues = getValues();
    const hops = [];
    formValues.hops.forEach(({ hop_start_id, hop_end_id }) => {
      if (!hops.includes(hop_start_id)) {
        hops.push(hop_start_id);
      }
      hops.push(hop_end_id);
    });

    return AirportList.filter((item) => hops.includes(item.id));
  };

  return (
    <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {hopFields.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    <Controller
                      name={`hops.${index}.hop_empty`}
                      control={control}
                      render={({ field: { onChange, value, ...req } }) => (
                        <Checkbox
                          loadIcon
                          value={!value}
                          onChange={(e, onChangeValue) => {
                            onChange(!onChangeValue);
                          }}
                          {...req}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <FormGroup required hasError={errors?.hops?.[index]?.hop_start_id}>
                      <Controller
                        name={`hops.${index}.hop_start_id`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ...req } }) => (
                          index === 0
                            ? (
                              <Autocomplete
                                {...req}
                                freeSolo
                                sx={{
                                  '& .MuiAutocomplete-popupIndicator': {
                                    marginRight: '2px',
                                  },
                                  width: '14rem'
                                }}
                                isLoading={airportListLoad}
                                label="Airport From"
                                onChange={(e, data) => {
                                  handleChangeStartAirport(e, data, index, true);
                                  onChange(data?.value);
                                }}
                                size="small"
                                options={formattedAirportList}
                                value={formattedAirportList?.find((AirportItem) => AirportItem.id === value) || ''}
                                placeholder="Airport From"
                              />
                            )
                            : (
                              <Input
                                {...req}
                                disabled
                                label="Airport From"
                                value={watch(`hops.${index}.hop_start`) || ''}
                                size="small"
                                sx={{
                                  width: '14rem',
                                }}
                              />
                            )
                        )}
                      />

                      <FieldError error={errors?.hops?.[index]?.hop_start_id} />
                    </FormGroup>
                  </TableCell>
                  <TableCell align="center">
                    <ArrowRight />
                  </TableCell>
                  <TableCell>
                    <FormGroup required hasError={errors?.hops?.[index]?.hop_end_id}>
                      <Controller
                        name={`hops.${index}.hop_end_id`}
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: {
                            onChange,
                            value,
                            ...req
                          },
                        }) => (
                          <Autocomplete
                            {...req}
                            freeSolo
                            label="Airport To"
                            sx={{
                              '& .MuiAutocomplete-popupIndicator': {
                                marginRight: '2px',
                              },
                              width: '14rem'
                            }}
                            isLoading={airportListLoad}
                            onChange={(e, data) => {
                              handleChangeStartAirport(e, data, index);
                              onChange(data?.value || '');
                            }}
                            size="small"
                            options={formattedAirportList}
                            value={formattedAirportList?.find((AirportItem) => AirportItem.id === value) || ''}
                            placeholder="Airport To"
                          />
                        )}
                      />

                      <FieldError error={errors?.hops?.[index]?.hop_end_id} />
                    </FormGroup>
                  </TableCell>

                  <TableCell>
                    <FormGroup>
                      <Controller
                        name={`hops.${index}.hop_ap_fees_f`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            disabled
                            label="DEP A/P fees"
                            placeholder="DEP A/P fees"
                            sx={{
                              minWidth: '100px',
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <Controller
                        name={`hops.${index}.hop_ap_fees_t`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            disabled
                            label="ARR A/P fees"
                            placeholder="ARR A/P fees"
                            sx={{
                              minWidth: '100px',
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <Controller
                        name={`hops.${index}.fuel_price`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            disabled
                            label="Fuel Price"
                            placeholder="Fuel Price"
                            sx={{
                              minWidth: '100px',
                            }}
                          />
                        )}
                      />
                      {(!!watch(`hops.${index}.fuel_price`) && (+watch(`hops.${index}.fuel_price`) < 0.5)) && <FieldError error={{ message: 'Check Fuel Price' }} />}
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <Controller
                        name={`hops.${index}.hop_distance`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            label="Distance"
                            disabled
                            placeholder="Distance"
                            sx={{
                              minWidth: '70px',
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <Controller
                        name={`hops.${index}.hop_flttime`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            label="Flt.Time"
                            disabled
                            placeholder="Flt.Time"
                            sx={{
                              minWidth: '70px',
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <Controller
                        name={`hops.${index}.leg_fuel_price`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            disabled
                            label="Leg Fuel Price (EUR)"
                            placeholder="Leg Fuel Price (EUR)"
                            sx={{
                              minWidth: '70px',
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    {index > 0 && (
                    <StyledDelete
                      onClick={() => { handleRemoveHop(index); }}
                    />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <Button
              variant="contained"
              size="small"
              sx={{ margin: '2rem 0.75rem 1rem 0.75rem', padding: '0.75rem' }}
              onClick={addNewHop}
            >
              <Plus sx={{ mr: 1 }} />
              Add Fleet
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ margin: '2rem 0.75rem 1rem 0', padding: '0.75rem' }}
              onClick={handleOpenModal}
            >
              Change a/p fees
            </Button>
          </Box>

        </TableContainer>
      </Grid>

      <FeesModal
        open={isModalOpen}
        onClose={handleCloseModal}
        airportList={getHopsAirportList()}
        setQuotation={setQuotation}
        refetchAirportList={refetchAirportList}
        feesKey={getFeesKey()}
        handleChangedAirport={handleChangedAirport}
        handleChangeHops={handleChangeHops}
      />
    </Grid>
  );
};

export default HopsForm;
