import { Chip } from '@mui/material';

export const ORDER_STATUS = [
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '0', icon: (<Chip label="SENT" size="small" color="secondary" sx={{ fontWeight: 'bolder' }} />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '1', icon: (<Chip label="PENDING APPROVE" size="small" color="warning" sx={{ fontWeight: 'bolder' }} />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '2', icon: (<Chip label="APPROVED" size="small" color="info" sx={{ fontWeight: 'bolder' }} />) },
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '3', icon: (<Chip label="ON THE WAY" size="small" color="primary" sx={{ fontWeight: 'bolder' }} />) },
];

export const CARRIERS = [
  { label: 'Caddy New', value: 'Caddy New' },
  { label: 'Caddy Old', value: 'Caddy Old' }
];

export const deliveryTypes = [
  { label: 'Part Delivery', value: 'delivery' },
  // { label: 'Overhaul', value: 'Overhaul' },
  { label: 'Overhaul In', value: 'Overhaul_in' },
  { label: 'Overhaul Out', value: 'Overhaul_out' },
  // { label: 'Repair', value: 'Repair' },
  { label: 'Repair In', value: 'Repair_in' },
  { label: 'Repair Out', value: 'Repair_out' },
  { label: 'Exchange', value: 'Exchange' },
  { label: 'Return', value: 'Return' },
  { label: 'Other', value: 'Other' },
];

export const internalAccess = [
  'internal_logistics_order_read',
  'internal_logistics_order_read_aog',
  'internal_logist',
  'internal_logistics_order_approve',
  'internal_logistics_storage_master'
];

export const externalAccess = [
  'logistics_order_read',
  'logistics_order_read_aog',
  'logist',
  'logistics_order_approve',
  'logistics_storage_master'
];

export const myOrdersInternalAccess = [
  'internal_logistics_order_read',
  'internal_logistics_order_read_aog',
  'internal_logistics_order_creator',
];

export const myOrdersExternalAccess = [
  'logistics_order_creator',
  'logistics_order_read',
  'logistics_order_read_aog',
  'logistics_aircraft_reservation'
];

export const dashboardInternalAccess = [
  'internal_logistics_order_creator',
  'internal_logist',
  'internal_logistics_order_approve',
  'internal_logistics_storage_master',
  'internal_logistics_order_read',
  'internal_logistics_order_read_aog',
  'internal_logistics_storage_master',
];

export const dashboardExternalAccess = [
  'logist',
  'logistics_order_approve',
  'logistics_order_creator',
  'logistics_storage_master',
  'logistics_order_read_aog',
  'logistics_order_read',
  'logistics_storage_read',
  'logistics_storage_write',
  'logistics_aircraft_reservation',
];

export const storageAccess = [
  'logist', 
  'internal_logist', 
  'logistics_storage_master', 
  'logistics_order_creator', 
  'internal_logistics_storage_master', 
  'internal_logistics_order_creator', 
  'logistics_storage_read', 
  'logistics_storage_write', 
  'internal_logistics_storage_write'
];

export const AllInternalAccess = [
  'internal_logistics_order_creator',
  'internal_logistics_order_read',
  'internal_logist',
  'internal_logistics_order_approve',
  'internal_logistics_storage_master',
  'internal_logistics_storage_read',
  'internal_logistics_storage_write',
  'internal_logistics_suppliers_read',
  'internal_logistics_suppliers_write',
  'internal_logistics_aircraft_reservation',
  'internal_logistics_order_read_aog',
];

export const AllExternalAccess = [
  'invoice_request_logist',
  'internal_invoice_request_logist',
  'invoice_request_logist_approve',
  'logistics_order_creator',
  'logistics_order_read',
  'logist',
  'logistics_order_approve',
  'logistics_storage_master',
  'logistics_storage_read',
  'logistics_storage_write',
  'logistics_suppliers_read',
  'logistics_suppliers_write',
  'logistics_aircraft_reservation',
  'logistics_order_read_aog',
];